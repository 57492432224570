import React, { Suspense } from "react";
import { graphql } from "gatsby";
// import { timeout } from 'promise-timeout'

// import BookingLayout from "../../components/BookingLayout";
import SEO from "../../components/SEO";
import Whatsapp from "../../components/Whatsapp";
import loadable from '@loadable/component'

// const BookingLayout = loadable(
//   () =>import('../../components/BookingLayout'),
//   {
//     delay:10000,
//     fallback: <div class="loaderBod"><div class="loader2"></div></div>
//   }
//   )

const BookingIndex = loadable(
  () =>import('../../components/BookingIndex'),
  {
    delay:10000,
    fallback: <div class="loaderBod"><div class="loader2"></div></div>
  }
  )


// const BookingLayout = React.lazy(() =>
//   import("../../components/BookingLayout")
// ); // Lazy-loaded

// function Main(props) {
//       const isSSR = typeof window === "undefined"

//   return {!isSSR &&(
//     <Suspense fallback={<div class="loader"></div>}>
//       <Booking {...props} />
//     </Suspense>
//   )}
// }


function Booking({ data }) {
  let daa = data.allStrapiServices.nodes;
  return (
    <>
    <SEO title={"Book Service"}/>
    <BookingIndex daa={daa}/>
    <Whatsapp/>
    </>
  );
}

export const query = graphql`
  {
    allStrapiServices {
      nodes {
        title
        slug
        icon {
          localFile {
            publicURL
          }
        }
      }
      totalCount
    }
  }
`;

export default Booking;
